/** @jsx jsx */
import { jsx } from "theme-ui"
import { useContext } from "react"

import { TOCContext } from "../context/tocContext"

export default function TableOfContents() {
  const tocContext = useContext(TOCContext)
  const selectedHeading = tocContext.selectedHeading

  const getHeadingStyle = (headingValue) =>
    selectedHeading && headingValue === selectedHeading.value
      ? {
          borderLeft: "solid 4px",
          borderColor: "ranglered",
          paddingLeft: "5px",
        }
      : { paddingLeft: "9px" }

  return (
    <div sx={{ display: ["none", "block"], height: "40vh" }}>
      {tocContext.headings.map((heading) =>
        heading.depth === 1 ? (
          <a
            href={`#${heading.value.toLowerCase().split(" ").join("-")}`}
            key={heading.value.split(" ").join("-")}
          >
            <h4 sx={getHeadingStyle(heading.value)}>{heading.value}</h4>
          </a>
        ) : (
          <a
            href={`#${heading.value.toLowerCase().split(" ").join("-")}`}
            key={heading.value.split(" ").join("-")}
          >
            <h5 sx={getHeadingStyle(heading.value)}>{heading.value}</h5>
          </a>
        )
      )}
    </div>
  )
}
