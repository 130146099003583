/** @jsx jsx */
import { Flex, jsx, Styled } from "theme-ui"
import { createRef, useCallback, useEffect, useState } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import debounce from "lodash.debounce"

import { TOCContext, initialState } from "../context/tocContext"
import Layout from "../components/Layout"
import { ReadingProgress } from "../components/ReadingProgress"
import TopicLinks from "../components/TopicLink"
import Breadcrumbs from "../components/Breadcrumbs"
import TableOfContents from "../components/TableOfContents"
import { getDisplayedHeadings } from "../util/tableOfContentsUtil"
import {
  lookupReadingPositions,
  updateReadingPercentage,
  updateLastReadTitle,
} from "../util/readingPosition"

export default function L2(props) {
  const [tocContext, setTOCContext] = useState(initialState)
  const data = props.data.mdx
  const linkData = props.data.allMdx.edges

  const hasL2 = !!data.fields.levels?.L2
  const leftMargin = hasL2 ? "96px" : "48px"

  useEffect(() => {
    const position = lookupReadingPositions()
    const title = data.frontmatter.title
    if (
      position &&
      position[title] &&
      position[title].lastTitle &&
      !window.location.hash
    ) {
      window.location.hash = `#${position[title].lastTitle
        .toLowerCase()
        .split(" ")
        .join("-")}`
    }
  })

  const scrollTarget = createRef()

  const debouncedReadingUpdate = useCallback(
    debounce((title, lastTitle) => updateLastReadTitle(title, lastTitle), 300),
    []
  )

  const debouncedReadPercentageUpdate = useCallback(
    debounce(
      (title, percentage) => updateReadingPercentage(title, percentage),
      300
    ),
    []
  )

  return (
    <TOCContext.Provider
      value={{
        ...tocContext,
        headings: getDisplayedHeadings(
          data.headings,
          tocContext.selectedHeading
        ),
        setToC: (context) => {
          if (context.selectedHeading) {
            debouncedReadingUpdate(
              data.frontmatter.title,
              context.selectedHeading.value
            )
          }
          setTOCContext({
            ...context,
            headings: getDisplayedHeadings(
              data.headings,
              tocContext.selectedHeading
            ),
          })
        },
      }}
    >
      <div
        sx={{
          height: ["auto", "100vh"],
          width: ["auto", leftMargin],
          position: ["", "fixed"],
          display: ["none", "block"],
          left: ["", 0],
          top: ["", 0],
        }}
      >
        <Breadcrumbs levels={data.fields.levels} />
      </div>

      <Layout page="content" levels={data.fields.levels}>
        <div sx={{ display: ["", "none"] }}>
          <Breadcrumbs levels={data.fields.levels} />
        </div>
        <div sx={{ marginLeft: ["", leftMargin] }}>
          <article
            sx={{
              marginBottom: "8rem",
              display: ["", "flex"],
              flexDirection: ["", "column"],
            }}
          >
            <figure
              sx={{
                minHeight: ["200px", "300px"],
                height: "50vh",
                width: "100%",
                margin: 0,
                img: {
                  marginBottom: 0,
                  objectFit: "cover",
                  minWidth: ["auto", "100%"],
                },
              }}
            >
              <Img
                fluid={data.frontmatter.hero_image.childImageSharp.fluid}
                alt={data.frontmatter.title}
              />
            </figure>

            <ReadingProgress
              target={scrollTarget}
              scrollUpdate={(percentage) =>
                debouncedReadPercentageUpdate(
                  data.frontmatter.title,
                  percentage
                )
              }
            />
            <Flex
              sx={{
                flexDirection: ["column", "row"],
              }}
            >
              <div ref={scrollTarget} sx={{ width: ["100%", "70%"] }}>
                <div
                  sx={{
                    padding: ["1.5rem 1.25rem", "2rem 0", "3rem 0"],
                    width: "100%",
                    maxWidth: "768px",
                    margin: "0 auto",
                    textAlign: ["", "center"],
                  }}
                >
                  <Styled.h1
                    sx={{
                      marginBottom: "0.66rem",
                      maxWidth: ["auto", "500px"],
                      margin: "0 auto 0.66rem auto",
                    }}
                  >
                    {data.frontmatter.title}
                  </Styled.h1>
                </div>

                <div
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: ["0 1.25rem", "0 2rem"],
                    margin: "0 auto",
                    maxWidth: ["auto", "800px"],
                  }}
                >
                  <div sx={{ textAlign: "right", margin: "10px" }}>
                    <span sx={{ opacity: 0.7 }}>
                      {data.fields.readingTime.text}
                    </span>
                  </div>
                  <MDXRenderer>{data.body}</MDXRenderer>
                </div>
              </div>
              <div
                sx={{
                  width: ["100%", "30%"],
                  marginTop: 5,
                  position: "sticky",
                  alignSelf: "flex-start",
                  top: 5,
                }}
              >
                <TableOfContents />
              </div>
            </Flex>
          </article>
        </div>

        <TopicLinks data={linkData} />
      </Layout>
    </TOCContext.Provider>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        levels {
          L1
          L2
        }
        readingTime {
          text
        }
      }
      headings {
        depth
        value
      }
      frontmatter {
        title
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
    allMdx(
      filter: { fields: { levels: { L1: { ne: null }, L2: { eq: $slug } } } }
      sort: { order: ASC, fields: fields___priority }
    ) {
      edges {
        node {
          fields {
            slug
            levels {
              L1
              L2
            }
          }
          id
          frontmatter {
            title
            hero_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            coming_soon
          }
        }
      }
    }
  }
`
