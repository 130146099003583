export const getDisplayedHeadings = (headings, selectedHeading) => {
  let isSearch = true
  let isFound = false

  return headings.reduce((prev, curr) => {
    if (!selectedHeading) {
      return curr.depth === 1 ? [...prev, curr] : prev
    }

    if (selectedHeading.depth === 1) {
      if (curr.depth === 1) {
        if (isFound) {
          isSearch = false
        }
        if (selectedHeading.value === curr.value) {
          isFound = true
        }
        return [...prev, curr]
      }

      if (curr.depth === 2 && isFound && isSearch) {
        return [...prev, curr]
      }
      return prev
    }

    if (selectedHeading.depth === 2) {
      if (curr.depth === 1) {
        if (isFound) {
          isSearch = false
          return [...prev, curr]
        } else {
          return [...prev.filter((x) => x.depth === 1), curr]
        }
      }

      if (curr.depth === 2) {
        if (curr.value === selectedHeading.value) {
          isFound = true
        }
        if (isFound && !isSearch) {
          return prev
        }
        return [...prev, curr]
      }
    }

    return prev
  }, [])
}
