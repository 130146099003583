/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"

export default function Breadcrumbs({ levels }) {
  const breadcrumbTextStyle = {
    margin: 0,
    textAlign: ["left", "right"],
    padding: "0.5rem",
    paddingRight: ["", "4rem"],
    textTransform: "capitalize",
    borderTop: "1px solid #ebebeb",
    borderBottom: "1px solid #ebebeb",
    borderColor: "lighttext",
  }

  return (
    <div
      sx={{
        width: ["100%", "100vh"],
        position: ["", "absolute"],
        top: ["", 0],
        right: ["", 0],
        transform: ["", "rotateZ(-90deg)"],
        transformOrigin: ["", "right bottom"],
        marginTop: ["", "-50px"],
        "a:hover": {
          opacity: 1,
          h2: {
            transform: ["", "translateX(10px)"],
            transition: ["", "transform 0.5s ease-out"],
          },
        },
        ...(levels?.L2 ? { marginTop: ["", "-100px"] } : {}),
      }}
    >
      {levels?.L1 && (
        <Link to={`/${levels?.L1}`}>
          <Styled.h2 sx={breadcrumbTextStyle}>{levels?.L1}</Styled.h2>
        </Link>
      )}
      {levels?.L2 && (
        <Link to={`/${levels?.L1}/${levels?.L2}`}>
          <Styled.h2 sx={breadcrumbTextStyle}>
            {levels?.L2?.split("-")?.join(" ")}
          </Styled.h2>
        </Link>
      )}
    </div>
  )
}
